import { asyncRoutes, constantRoutes } from "@/router/index";
import router from "@/router/index";

/**
 * 过滤路由
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some((role) => route.meta.roles.includes(role));
  } else {
    return true;
  }
}

/**
 * 过滤路由
 * @param routes asyncRoutes
 * @param roles
 */
function filterAsyncRoutes(routes, roles) {
  const res = [];
  routes.forEach((route) => {
    if (hasPermission(roles, route)) {
      if (route.children) {
        route.children = filterAsyncRoutes(route.children, roles);
      }
      res.push(route);
    }
  });
  return res;
}

const state = {
  routes: [],
  hasAuth: false,
  removeRoutes: [], // 用于删除动态路由
};
const mutations = {
  SET_ROUTES: (state, routes) => {
    state.routes = constantRoutes.concat(routes);
  },
  HAS_AUTH: (state, val) => {
    state.hasAuth = val
  },
  SET_REMOVE_ROUTES: (state, routes) => {
    state.removeRoutes = routes;
  },
};
const actions = {
  generateRoutes({ dispatch, commit }, roles) {
    return new Promise((resolve) => {
      let accessedRoutes;
      const rolesArray = roles ? roles.split(",") : [];
      if (rolesArray.includes("visitor")) {
        accessedRoutes = asyncRoutes || [];
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, rolesArray);
      }
      console.log("accessedRoutes",accessedRoutes);
      commit("SET_ROUTES", accessedRoutes);
      commit("HAS_AUTH", true);
      dispatch("addRoutes", accessedRoutes);
      resolve(accessedRoutes);
    });
  },
  addRoutes({ commit }, accessRoutes) {
    // 添加动态路由，同时保存移除函数，将来如果需要重置路由可以用到它们
    const removeRoutes = [];
    accessRoutes.forEach((route) => {
      const removeRoute = router.addRoute(route);
      removeRoutes.push(removeRoute);
    });
    commit("SET_REMOVE_ROUTES", removeRoutes);
  },
  resetRoutes({ commit, state }) {
    // 重置路由为初始状态，用户切换角色时需要用到
    state.removeRoutes.forEach(fn => fn());
    // 路由数据重置
    commit("SET_ROUTES", []);
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
