
const state = {
  eventlive_show_login: false,
  eventlive_show_reg: false,
  eventlive_show_finish: false,
};
const mutations = {
  SET_EVENTLIVE_SHOW_LOGIN: (state, data) => {
    state.eventlive_show_login = data;
  },
  SET_EVENTLIVE_SHOW_REG: (state, data) => {
    state.eventlive_show_reg = data;
  },
  SET_EVENTLIVE_SHOW_FINISH: (state, data) => {
    state.eventlive_show_finish = data;
  },
};
const actions = {
  
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
