<template>
  <el-breadcrumb class="app-breadcrumb" separator-icon="ArrowRight">
    <i class="iconfont icon-home-fill"></i>
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
        <span
          v-if="item.redirect === 'noRedirect' || index == levelList.length - 1"
          class="no-redirect"
          >{{ item.meta.title }}</span
        >
        <a v-else @click.prevent="handleLink(item)" class="redirect">{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import { reactive, watch, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import { compile } from "path-to-regexp";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();

    const state = reactive({
      levelList: null,
    })

    watch(()=>router.currentRoute.value,()=>{
      getBreadcrumb();
    })
    getBreadcrumb();
    console.log("---crumb",route.matched);
    function getBreadcrumb() {
      let matched = route.matched.filter(
        item => item.meta && item.meta.title
      );
      const first = matched[0];

      if (!isIndex(first)) {
        matched = [{ path: "/", meta: { title: "概览" } }].concat(
          matched
        );
      }

      state.levelList = matched.filter(
        item => item.meta && item.meta.title && item.meta.breadcrumb !== false
      );
    }

    
    function isIndex(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return (
        name.trim().toLocaleLowerCase() === "home".toLocaleLowerCase()
      );
    }

    function pathCompile(path) {
      const { params } = route;
      var toPath = compile(path);
      return toPath(params);
    }

    function handleLink(item) {
      const { redirect, path } = item;
      if (redirect) {
        router.push(redirect);
        return;
      }
      router.push(pathCompile(path));
    }

    return {
      ...toRefs(state),
      handleLink
    };
  },
};
</script>

<style lang="less" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;
  i {
    margin-right: 5px;
    color: @color_deep;
  }
  .el-breadcrumb__item {
    float: none;
  }
  .no-redirect {
    color: @color_deep;
    cursor: text;
  }
  a {
    font-weight: normal;
    &:hover {
      color: @color_deep;
    }
  }
}
</style>
