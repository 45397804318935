<template>
  <div class="bg">
    <Header @showMenu="showMenu"></Header>

    <div class="container">

      <Sidebar class="sidebar-container" :class="{'active':show_nav}">
      </Sidebar>

      <div class="container-con" id="mainContainer">
        <!-- 面包屑 -->
        <breadcrumb class="breadcrumb-container" />

        <!-- 内容区 -->
        <AppMain></AppMain>

        <!-- 底部 -->
        <Footer></Footer>
      </div>
    </div>
  </div>

  <el-dialog v-model="dialogCertVisible" :show-close="false" :align-center="true" class="dialog-notitle" width="500" :close-on-click-modal="false">
    <div class="tip-popbox">
      <p class="tit">Real-name admission, please go to the personal information page to fill in the ID number, in order to obtain your admission code!</p>

      <div class="btnbox">
        <router-link :to="`${$t('baseurl')}/profile`"><el-button type="primary" size="large">Access to personal information</el-button></router-link>
      </div>
    </div>
  </el-dialog>


  <el-dialog v-model="dialogSurveyVisible" :show-close="false" :align-center="true" class="dialog-notitle" width="500" :close-on-click-modal="false">
    <div class="tip-popbox">
      <!-- 媒体 -->
      <p class="tit" v-if="prev_visitor_type == 2">Your application for a media visitor badge has not been approved. We have successfully converted it to a professional visitor status for you. Please fill out the following questionnaire to complete the registration.</p>
      <!-- 特邀 -->
      <p class="tit" v-else-if="prev_visitor_type == 3">Your application for a invited buyers badge has not been approved. We have successfully converted it to a professional visitor status for you. Please fill out the following questionnaire to complete the registration.</p>
      <p class="tit" v-else>Your questionnaire has not yet been submitted, please go to the questionnaire information first</p>
      <div class="btnbox">
        <router-link :to="`${$t('baseurl')}/survey`"><el-button type="primary" size="large">Access questionnaire information</el-button></router-link>
      </div>
    </div>
  </el-dialog>
</template>


<script>
import { toRefs, reactive, defineComponent, watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Sidebar from "./components/Sidebar/Index.vue";
import Breadcrumb from "./components/Breadcrumb/index.vue";
import AppMain from "./components/AppMain.vue";


export default defineComponent ({
  components: {
    Header,
    Footer,

    Sidebar,
    Breadcrumb,
    AppMain,
  }, 
  beforeRouteEnter(to, from, next) {
    // A跳转到B，B页面停留在A页面的滚动位置；解决方法：将scrollTop设置为0
    window.scroll(0, 0);
    next();
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const state = reactive({
      show_nav: false,
      dialogCertVisible: false,
      dialogSurveyVisible: false
    })

    const showMenu = (status)=>{
      state.show_nav = status;
    }

   

    watch(() => router.currentRoute.value,(newVal)=>{
      dialogStatus(newVal);
    })

    // 弹窗状态
    const prev_visitor_type = ref(null);
    dialogStatus(route);
    function dialogStatus(data) { 
      const expo_list = store.getters.expo_list;
      prev_visitor_type.value = expo_list[0].prev_visitor_type;

      if(data.meta.chkUserInfo) {
        if(store.getters.is_need_completion_certificates) {
          state.dialogCertVisible = true;
        }else if(store.getters.is_need_completion_survey){
          state.dialogSurveyVisible = true;
        }
      }else {
        state.dialogCertVisible = false;
        state.dialogSurveyVisible = false;
      }
    }
    

    return {
      ...toRefs(state),
      prev_visitor_type,
      showMenu
    }
  },

});
</script>