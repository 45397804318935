<template>
  <el-steps :active="process" align-center class="reg-process" process-status="wait">
    <el-step v-for="item in title" :key="item" :title="item" />
  </el-steps>

  <!-- <div class="process-step">
    <div class="top-bg"><span :style="{width: width}"></span></div>
    <div class="li" :class="process >= 1 ? 'curr' : ''">
        <span>{{ process >= 1 ? '' : '1'}}</span>
        <div class="tit">填写个人信息</div>
    </div>
    <div class="li" :class="process >= 2 ? 'curr' : ''">
        <span>{{ process >= 2 ? '' : '2'}}</span>
        <div class="tit">预登记问卷调查</div>
    </div>
    <div class="li" :class="process >= 3 ? 'curr' : ''">
        <span>{{ process >= 3 ? '' : '3'}}</span>
        <div class="tit">登记完成</div>
    </div>
</div> -->
</template>

<script>
import {} from "vue";

export default {
  props: {
    width: {
        type: String,
        default: "27.33%",
    },
    process: {
        type: Number,
        default: 1
    },
    title: {
      type: Array,
      default() {
        return ["Personal information", "Questionnaire", "Registration Completed"]
      }
    }
  }
}
</script>

<style lang="less">
  .reg-process {
    margin-bottom: 30px;
    .el-step__title {
      font-size: 15px;
      font-weight: bold;
      line-height: 1.5;
      margin-top: 5px;
    }

    .el-step__head.is-finish {
      color: @color_main;
      border-color: @color_main;
      
      .el-step__icon {
        background: #f9d1ca;
        box-shadow: 0 0 10px rgba(249, 209, 202, 0.6);
        position: relative;
        &:before {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: @color_deep;
        }
      }
      .el-step__icon-inner {
        display: none;
      }
    }
    .el-step__title.is-finish {
      color: @color_deep;
    }

    .el-step.is-horizontal .el-step__line {
      background: #f4e9eb;
      height: 4px;
    }
    .el-step__line-inner {
      border-width: 2px !important;
    }
    .el-step__icon {
      background: @color_main;
      color: #fff;
      border: 0 !important;
      
      width: 28px;
      height: 28px;
    }
    .el-step__icon-inner {
      font-weight: normal;
    }
  }

</style>