<template>

  <Dialog
    ref="dialogRegRef"
    :dialogObject="dialogRegOpt"
    @dialogClose="closeDialogReg"
    class="dialog-auth"
  >
    <div class="memberauth-con login-con">
      <div class="toptxt">Already have the account, <span @click="toLogin">returns to login in</span></div>

      <h2 class="title2">Register</h2>

      <!-- 注册进度条 -->
      <regProcess :process="reg_step" :title="['Create Account','Personal information','Registration Completed']"></regProcess>

      <!-- 账号 -->
      <el-form
        ref="formRef1"
        :model="formModel"
        :rules="formRules1"
        require-asterisk-position="right"
        class="login-formbox"
        v-if="reg_step == 1"
      >
          <el-form-item prop="email">
            <el-input
              type="text"
              v-model="formModel.email"
              placeholder="Please enter your email"
              class="form-input"
            />
          </el-form-item>

          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="formModel.password"
              show-password
              placeholder="Please enter the login password (8-16 characters, letters, numbers)"
              class="form-input"
            />
          </el-form-item>

          <el-form-item prop="password2">
            <el-input
              type="password"
              v-model="formModel.password2"
              show-password
              placeholder="Please enter password again"
              class="form-input"
            />
          </el-form-item>
      </el-form>

      <!-- 基本信息 -->
      <el-form
        ref="formRef2"
        :model="formModel"
        :rules="formRules2"
        require-asterisk-position="right"
        class="login-formbox"
        v-else-if="reg_step == 2"
        label-width="120"
      >
          <el-form-item label="Name" required>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item prop="truename1">
                  <el-input
                    type="text"
                    v-model="formModel.truename1"
                    placeholder="First Name"
                    class="form-input"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="truename2">
                  <el-input
                    type="text"
                    v-model="formModel.truename2"
                    placeholder="Last Name"
                    class="form-input"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item prop="gender" label="Title" required>
            <el-radio-group
              v-model="formModel.gender"
            >
              <el-radio :label="1">Mr.</el-radio>
              <el-radio :label="2">Ms.</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item prop="company" label="Company Name" required>
            <el-input
              type="text"
              v-model="formModel.company"
              placeholder="Please enter Company Name"
              class="form-input"
            />
          </el-form-item>

          <el-form-item prop="position_id" label="Job Title" required>
            <el-input
              type="text"
              v-model="formModel.position_id"
              placeholder="Please enter Job Title"
              class="form-input"
            />
          </el-form-item>

          <el-form-item prop="email" label="Email" required>
            <el-input
              type="text"
              v-model="formModel.email"
              placeholder="Please enter your email"
              class="form-input"
              :disabled="formModel.reg_type == 2 && has_email"
            />
          </el-form-item>

          <el-form-item prop="mobile" label="Mobile" required>
            <el-row :gutter="10">
              <el-col :span="6">
                <el-input
                  type="text"
                  v-model="formModel.mobile_code"
                  placeholder="Country/Region"
                  class="form-input"
                  :readonly="true"
                />
              </el-col>
              <el-col :span="18">
                <el-input
                  type="text"
                  v-model="formModel.mobile"
                  placeholder="Please enter your mobile phone"
                  class="form-input"
                  :disabled="formModel.reg_type == 1 && has_mobile"
                />
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item prop="country_id" label="Country/Region" required>
            <el-select
              v-model="formModel.country_id"
              value-key="id"
              filterable 
              placeholder="Please select"
              @change="changeCountry"
              class="form-input"
              :disabled="formModel.reg_type == 1 && formModel.country_id"
            >
              <el-option disabled label="Please select" />
              <template v-for="option in countryList" :key="option.id">
                <el-option :label="option.name_en" :value="option.id" :disabled="option.id == 256"/>
              </template>
              
            </el-select>
          </el-form-item>

          <el-form-item prop="address1" label="Address 1">
            <el-input
              type="text"
              v-model="formModel.address1"
              placeholder="Address 1"
              class="form-input"
            />
          </el-form-item>

          <el-form-item prop="address2" label="Address 2">
            <el-input
              type="text"
              v-model="formModel.address2"
              placeholder="Address 2"
              class="form-input"
            />
          </el-form-item>

          <el-form-item prop="address3" label="Address 3">
            <el-input
              type="text"
              v-model="formModel.address3"
              placeholder="Address 3"
              class="form-input"
            />
          </el-form-item>
      </el-form>

      <div class="submit-box">
        <el-button type="primary" @click="doRegister" :loading="loading"
          >Next</el-button
        >
      </div>
      

    </div>
  </Dialog>
</template>

<script>
import { reactive, toRefs, ref, unref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { isMobile, isEmail, isPassword, scrollToTarget, getCertificatesType } from "@/utils/common";
import { getCountryList, getRegionList, checkVisitorExist, sendSmsCode } from "@/api/common";
import showMsg from "@/utils/message";

import { doReg } from "@/api/base";
import Dialog from "@/components/Dialog/Dialog.vue";
import regProcess from "@/views/visitor/cn/Auth/components/regProcess.vue";
import Cookies from "js-cookie";

export default {
  components: {
    Dialog,
    regProcess
  },

  setup(props, ctx) {

    const store = useStore();

    const chkMobile = async(rule, val, callback) => {
      if (!val) {
        callback(new Error("Please enter your mobile phone"));
      } else if(val.length < 6) {
        callback(new Error("Please enter a valid phone number"));
      } else if(!state.has_mobile){
        const msg = await checkVisitorExist({ mobile: val, app_lang: "en", is_token: 1 })
          .then((res) => {
            const { data } = res;
            state.enableSmscode = true;
            state.has_mobile = true;
            state.formModel.token = data.token;
            return;
          })
          .catch((err) => {
            showMsg.error(err.msg);
            return new Error(err.msg)
          })
        callback(msg);
      }
      callback();
    };
    const chkEmail = async(rule, val, callback) => {
      if (!val) {
        callback(new Error("Please enter your email"));
      } else if(!isEmail(val)) {
        callback(new Error("Please enter a valid email"));
      } else if(!state.has_email && isEmail(val)){
        const msg = await checkVisitorExist({ email: val, app_lang: "en", is_token: 1 })
          .then((res) => {
            const { data } = res;
            state.has_email = true;
            state.formModel.token = data.token;
            return;
          })
          .catch((err) => {
            showMsg.error(err.msg);
            return new Error(err.msg)
          })
        callback(msg);
      }
      callback();
    };

   
    const chkPassword = (rule, val, callback) => {
      if (!val) {
        callback(new Error("Please enter password"));
      } else if (!isPassword(val)) {
        callback(new Error("The password must be a combination of 8 to 16 characters, including letters and numbers"));
      }
      callback();
    };
    const chkPassword2 = (rule, val, callback) => {
      const password = state.formModel.password;
      if (!val) {
        callback(new Error("Please enter password again"));
      } else if (password != val) {
        callback(new Error("Two passwords do not match"));
      }
      callback();
    };

    const state = reactive({
      loading: false,
      formModel: {
        app_lang: "en",
        is_current: 0,// 是否注册当届 1是 0否
        reg_type: 2,// 1:中国大陆 2：非中国大陆
        mobile: "",
        email: "",
        password: "",
        password2: "",
      },
      formRules1: {
        email: [
          { required:true,trigger:"blur",message:"Please enter your email" },
          { validator: chkEmail, trigger: "blur" }
        ],
        password: [
          { required:true,trigger:"blur",message:"Please enter password" },
          { pattern:/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/,message:"Please enter the login password (8-16 characters, letters, numbers)",trigger:"blur" }
        ],
        password2: [{ validator: chkPassword2, trigger: "blur" }],
      },
      formRules2: {
        mobile: [
          { required:true, validator: chkMobile, trigger: "blur" }
        ],
        truename1: [{ required:true,trigger:"blur",message:"Please enter your first name" }],
        truename2: [{ required:true,trigger:"blur",message:"Please enter your last name" }],
        gender: [{ required:true,trigger:"change",message:"Please select your gender" }],
        company: [{ required:true,trigger:"blur",message:"Please enter your company name" }],
        position_id: [{ required:true,trigger:"blur",message:"Please enter your position" }],
        country_id: [{ required:true,trigger:"change",message:"Please select country/region" }],
      },
      has_mobile: false,
      has_email: false,

      countryList: [],
      chk_country: null,

      // 注册
      reg_step: 1,
      dialogRegOpt: {
        dialogVisible: false,
        width: "600px",
        isFooter: false,
        clickModal: false
      }
    });

    // 大陆
    const changeRegType = (val) => {
      if (val == 1) {
        state.formModel.country_id = 256;
        changeCountry(256);
      }else {
        state.formModel.country_id = undefined;
        state.formModel.mobile_code = undefined;
        state.countryList.map((o,i)=>{
          if(o.id == 256) {
            state.countryList[i].disabled = true;
          }
        });
      }
    };

    // 获取国家列表
    getCountryList().then((res)=>{
      const {data} = res;
      state.countryList = data;
      
      changeRegType(state.formModel.reg_type);
    })

    // 国家
    const changeCountry = (id)=>{
      const option = state.countryList.filter(o=>o.id == id);
      state.formModel.mobile_code = option[0].code;
    }
    
    const formRef1 = ref(null);
    const formRef2 = ref(null);
    watch(()=>store.getters.eventlive_show_reg,(newVal)=>{
      state.dialogRegOpt.dialogVisible = newVal;
    })

    const closeDialogReg = ()=>{
      store.commit("eventlive/SET_EVENTLIVE_SHOW_REG",false);
    }
    const toLogin = ()=>{
      // 重置初始值
      if(state.reg_step == 1) {
        unref(formRef1).resetFields();
      }else {
        unref(formRef2).resetFields();
      }
      
      
      resetFormModel();

      store.commit("eventlive/SET_EVENTLIVE_SHOW_REG",false);
      store.commit("eventlive/SET_EVENTLIVE_SHOW_LOGIN",true);
    }

    // 注册
    const doRegister = () => {
      if(state.reg_step == 1) {
        // 第一步
        unref(formRef1).validate((valid) =>{
          if(valid) {
            // 校验通过
            state.reg_step = 2;
          }
        })
      }else {
        // 个人信息
        unref(formRef2).validate((valid) =>{
          if(valid) {
            // 校验通过
              state.loading = true;
            doReg(state.formModel)
            .then((res) => {
              Cookies.set("isLogin",true);
              Cookies.set("truename",state.formModel.truename1);

              store.commit("eventlive/SET_EVENTLIVE_SHOW_REG",false);
              store.commit("eventlive/SET_EVENTLIVE_SHOW_FINISH",true);
              unref(formRef2).resetFields();
              unref(formRef1).resetFields();
              resetFormModel();
              // 更新角色
              store.dispatch("user/changeRoles");
              state.loading = false;
            })
            .catch((err) => {
              state.loading = false;
              showMsg.error(err.msg);
              state.reg_step = 1;
              state.has_mobile = false;
              state.has_email = false;
            });
          }
        })
      }
      
    }

    // 重置初始值
    const resetFormModel = ()=>{
      state.reg_step = 1;
      state.formModel.reg_type = 1;
      state.formModel.mobile = "";
      state.formModel.email = "";
      state.formModel.code = "";
      state.formModel.password = "";
      state.formModel.password2 = "";
    }

    return {
      ...toRefs(state),
      closeDialogReg,
      changeRegType,
      changeCountry,
      formRef1,
      formRef2,
      doRegister,
      toLogin
    };
  },
};
</script>

<style lang="less" scoped>
</style>
