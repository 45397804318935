<template>
  <el-scrollbar class="scrollbar">
      <div class="sidebar-con" >
        <el-menu
          :default-active="activeMenu"
          text-color="#333333"
          active-text-color="#ec664e"
          :unique-opened="true"
          class="sidebar-menu"
        >
          <sidebar-item
            v-for="(route, index) in permissionRoutes"
            :key="route.path"
            :item="route"
            :base-path="route.path"
            :level="index"
          ></sidebar-item>

          <el-menu-item class="logout">
            <a @click="logout">
              <i class="iconfont icon-logout"></i>
              <span>Exit</span>
            </a>
          </el-menu-item>
        </el-menu>
        
    </div>
  </el-scrollbar>
</template>

<script>
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  components: { SidebarItem },
  computed: {
    ...mapGetters(["permissionRoutes"]),
    activeMenu() {
      const route = useRoute();
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      console.log("activeMenu",path);
      return path;
    },
  },
  setup(){
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    const logout = ()=>{
      store.dispatch("user/logout").then(()=>{
        router.push(`${t('baseurl')}/login`);
      });
      
    }

    return {
      logout
    }
  }
};
</script>

<style lang="less" scoped></style>
