import axios from "axios";
import { ElMessageBox } from "element-plus";
import showMsg from "@/utils/message";
import store from "@/store/index";
import i18n from '@/locales/i18n';

const t = i18n.global.t;

const instance = axios.create({
  // 在请求地址前面加上baseURL
  baseURL: process.env.VUE_APP_BASE_URL,
  // 设置请求超时时间
  timeout: 5*60*1000,
});

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // 请求错误的统一处理
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    // console.log("request-response" , response); // for debug
    const res = response.data;
    if(!res.code) {
      return res;
    }
    if(res.code === 200 || res.code === 1) {
      return res;
    }else {
      // || res.code === 10002
      if (res.code === 10001) {
        // 重新登录
        ElMessageBox.alert(t("Please log in"), {
            confirmButtonText: t("Log back in"),
            type: "warning",
            "show-close": false,
          })
          .then(() => {
            store.dispatch("user/logout").then(() => {
              if(t("lang") == 'cn') {
                window.location.href= `${store.state.domain}/login`
              }else {
                window.location.href=`${store.state.domain_en}/login`
              }
              
            });
          });
      }
      return Promise.reject(res);
      
    }
  },
  (error) => {
    console.log("request-err" , error); // for debug
    const { response } = error;
    if(response.status == 408) {
      showMsg.error(t("The request timed out. Please refresh and try again"), {duration: 5 * 1000,})
     
    }else {
      showMsg.error(error, {duration: 5 * 1000,})
    }
    
    return Promise.reject(error);
  }
);

export default instance;