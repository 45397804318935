<template>
  <template v-if="!item.hidden && !item.lang">
    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
        (!onlyOneChild.children || onlyOneChild.noShowingChildren)
      "
    >
      <el-menu-item
        v-if="onlyOneChild.meta"
        :index="resolvePath(onlyOneChild.path, onlyOneChild.name)"
      >
        <app-link :to="resolvePath(onlyOneChild.path, onlyOneChild.name)">
          <i
            v-if="onlyOneChild.meta.icon"
            class="iconfont"
            :class="onlyOneChild.meta.icon"
          ></i>
          <span>{{ onlyOneChild.meta.title }}</span>
        </app-link>
      </el-menu-item>
    </template>

    <template v-else>
      <el-sub-menu ref="subMenu" :index="resolvePath(item.path)">
        <template v-if="item.meta" v-slot:title>
          <i
            v-if="item.meta.icon"
            class="iconfont"
            :class="item.meta.icon"
          ></i>
          <span>{{ item.meta.title }}</span>
        </template>
        <sidebar-item
          v-for="child in item.children"
          :key="child.path"
          :item="child"
          :base-path="resolvePath(child.path)"
        ></sidebar-item>
      </el-sub-menu>
    </template>
  </template>
</template>

<script>
import path from "path-browserify";
import { isExternal } from "@/utils/common";
import appLink from "./Link.vue";
export default {
  components: {
    appLink,
  },
  data() {
    this.onlyOneChild = null;
    return {};
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    basePath: {
      type: String,
      default: "",
    },
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        } else {
          this.onlyOneChild = item;
          return true;
        }
      });
      if (showingChildren.length === 1) {
        return true;
      }
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: "", noShowingChildren: true };
        return true;
      }
      return false;
    },
    resolvePath(routePath,routeName) {
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(this.basePath)) {
        return this.basePath;
      }
      // 去除所有参数
      routePath = routePath.replace(/(:)(\w|\W)*/,"");

      return path.resolve(this.basePath, routePath);
    },
  },
};
</script>

<style lang="less" scoped></style>
