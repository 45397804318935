<template>
  <Dialog
    ref="dialogRegRef"
    :dialogObject="dialogRegOpt"
    @dialogClose="closeDialogReg"
    class="dialog-auth"
  >
    <div class="memberauth-con login-con">
      <div class="time">{{time}}s后关闭</div>

      <h2 class="title2">SIAL西雅展会员注册</h2>

      <!-- 注册进度条 -->
      <regProcess
        :process="reg_step"
        :title="['创建账号', '填写个人信息', '完成注册']"
      ></regProcess>

      <!-- <el-form
        ref="formRef"
        :model="formModel"
        :rules="formRules"
        require-asterisk-position="right"
        class="login-formbox"
      >
      <div class="form-row block">
        <p class="form-label"><strong>感兴趣的展品范围（多选）</strong></p>
        <div class="form-inputbox">
            <el-tree
                ref="industryTreeRef"
                :data="industryList"
                :props="{label: 'name_cn', children: 'childlist'}"
                node-key="id"
                show-checkbox
                :default-expanded-keys="checkedKeys"
                :default-checked-keys="checkedKeys"
                :check-on-click-node="true"
                class="tree-chkbox"
            />
        </div>
        </div>
      </el-form> -->

      <el-row class="success-tip">
        <div class="img"><img src="@/assets/images/ico-success.png" alt="" width="80"></div>
        <p class="tit">恭喜您已完成注册</p>
      </el-row>

      <!-- <div class="submit-box">
        <a href="/" target="_blank"><el-button type="primary">进入会员中心</el-button></a>
      </div> -->
    </div>
  </Dialog>
</template>

<script>
    import { reactive, toRefs, ref, unref, watch } from "vue";
    import { useRouter } from "vue-router";
    import { useStore } from "vuex";
    import { getIndustryTree } from "@/api/common";
    import showMsg from "@/utils/message";
    
    import Dialog from "@/components/Dialog/Dialog.vue";
    import regProcess from "@/views/visitor/cn/Auth/components/regProcess.vue";
    
    export default {
      components: {
        Dialog,
        regProcess
      },

      setup(){

        const store = useStore();

        const state = reactive({
            loading: false,
            time: 3,
            reg_step: 3,
            dialogRegOpt: {
                dialogVisible: store.getters.eventlive_show_finish,
                width: "600px",
                isFooter: false,
                clickModal: false
            },
            industryList: [],
            checkedKeys: [], // 选中id
        })

        const countdown = ()=>{
          const t = setInterval(()=>{
            if(state.time == 0) {
              clearInterval(t);
              closeDialogReg();
              return;
            }
            state.time --;
          },1000)
        }

        watch(()=>store.getters.eventlive_show_finish,(newVal)=>{
            state.dialogRegOpt.dialogVisible = newVal;
            if(newVal) {
              countdown();
            }
        })

        if(state.dialogRegOpt.dialogVisible) {
          countdown();
        }
        

        getIndustryTree().then((res)=>{
            const {data}= res;
            state.industryList = data;
        })

        const industryTreeRef = ref(null);
        const closeDialogReg = ()=>{
            store.commit("eventlive/SET_EVENTLIVE_SHOW_FINISH",false);
            state.checkedKeys = [];
            window.location.reload();
        }

        return {
            ...toRefs(state),
            industryTreeRef,
            closeDialogReg
        }
      }
      
    }
    
</script>

<style lang="less" scoped>
  .time {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #999;
  }
</style>
