<template>
  <div>
    <Header></Header>

    <div class="elive-container">
      <!-- 内容区 -->
      <AppMain></AppMain>
    </div>

    <!-- 底部 -->
    <Footer></Footer>

    <!-- 登录弹窗 -->
    <Login></Login>

    <!-- 注册弹窗 -->
    <Reg></Reg>
    <Finish></Finish>
  </div>
</template>


<script>
import { ref } from "vue";
import Header from "./components/Header.vue";
import Footer from "@/views/visitor/en/Auth/components/regFooter.vue";
import AppMain from "./components/AppMain.vue";
import Login from "@/views/eventLive/en/auth/Login.vue";
import Reg from "@/views/eventLive/en/auth/Reg.vue";
import Finish from "@/views/eventLive/en/auth/Finish.vue";



export default {
  components: {
    Header,
    Footer,
    AppMain,
    Login,
    Reg,
    Finish
  }, 
  setup(props) {
    
  },

};
</script>