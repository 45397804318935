import axios from "axios";
import showMsg from "@/utils/message";
import store from "@/store/index";

const instance = axios.create({
  // 在请求地址前面加上baseURL
  baseURL: "/journalapi",
  // 设置请求超时时间
  timeout: 6000,
});

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // 请求错误的统一处理
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    const res = response.data;
    if(!res.code) {
      return res;
    }
    if(res.code === 200 || res.code === 1) {
      return res;
    }else {
      return Promise.reject(res);
      
    }
  },
  (error) => {
    console.log("request-err" , error); // for debug
    showMsg.error(error, {duration: 5 * 1000,})
    return Promise.reject(error);
  }
);

export default instance;