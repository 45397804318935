import request from "@/utils/request";

/**
 * 注册-个人信息
 * @data {*}
 * @returns
 */
export function doReg(data) {
  return request({
    url: "/reg",
    method: "POST",
    data,
  });
}

/**
 * 获取问卷数据
 * @params {*}  expo_area_id：展会id
 * @returns
 */
export function getSurveyList(params) {
  return request({
    url: "/getSurveyList",
    method: "GET",
    params,
  });
}

/**
 * 保存问卷
 * @data {survey_data} 
 * @returns
 */
export function saveSurvey(data) {
  return request({
    url: "/saveSurvey",
    method: "POST",
    data,
  });
}

/**
 * 注册成功页
 * @data {expo_area_id} 展会id
 * @returns
 */
 export function getRegSuccess(params) {
  return request({
    url: "/getRegSuccess",
    method: "GET",
    params,
  });
}



/**
 * 底部订阅
 * @data {*}
 * @returns
 */
export function subscribePost(data) {
  return request({
    url: "/subscribe_post",
    method: "POST",
    data,
  });
}

/**
 * 修改密码
 * @data {*} mobile
 * @returns
 */
export function savePwd(data) {
  return request({
    url: "/save_pwd",
    method: "POST",
    data,
  });
}

/**
 * 获取个人信息
 *
 * @returns
 */
export function getVisitor(params) {
  return request({
    url: "/getVisitor",
    method: "GET",
    params,
  });
}

/**
 * 保存个人信息
 *
 * @returns
 */
export function saveVisitor(data) {
  return request({
    url: "/saveVisitor",
    method: "POST",
    data,
  });
}

/**
 * 保存配对信息
 *
 * @returns
 */
export function saveVisitorOther(data) {
  return request({
    url: "/saveVisitorOther",
    method: "POST",
    data,
  });
}




/**
 * 获取首页数据
 * @params {*}
 * @returns
 */
 export function getCenter(params) {
  return request({
    url: "/getCenter",
    method: "GET",
    params,
  });
}

/**
 * 退出
 *
 * @returns
 */
export function logout(data) {
  return request({
    url: "/logout",
    method: "POST",
    data,
  });
}


/**
 * 确认函
 * @params {expo_area_id} 展会id
 * @returns
 */
 export function getConfirmation(params) {
  return request({
    url: "/getConfirmation",
    method: "GET",
    params,
  });
}



/**
 * 找回密码
 *
 * @returns
 */
export function findPwd(data) {
  return request({
    url: "/findPwd",
    method: "POST",
    data,
  });
}

/**
 * 修改密码
 *
 * @returns
 */
export function changePassword(data) {
  return request({
    url: "/changePassword",
    method: "POST",
    data,
  });
}

/**
 * 检查观众是否存在某个展会
 *
 * @returns
 */
export function checkVisitorExpoAreaExistLogin(data) {
  return request({
    url: "/checkVisitorExpoAreaExistLogin",
    method: "POST",
    data,
  });
}

