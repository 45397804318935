<template>
  <div class="elive-header">
    <div class="elive-wrap">
      <div class="con">
        
        <div class="logo">
          <router-link :to="`${$t('baseurl')}/eventlive`"><img src="@/assets/images/logo.png?v=2023" alt="SIAL LOGO"
          /></router-link>
        </div>

        <div class="menubtn mb-show" :class="{'active' : is_nav}" @click="showMenu">
          <span></span>
          <span></span>
          <span></span>
        </div>

        <nav class="navbox" :class="{'active' : is_nav}" v-show="show_nav">
          <ul>
              <li class="nav-li">
                <router-link :to="`${$t('baseurl')}/eventlive`" :class="{'active': isActive == 1}">Home</router-link>
              </li>
              <!-- <li class="nav-li">
                <router-link :to="`${$t('baseurl')}/forumticket`" :class="{'active': isActive == 2}">Summit Tickets</router-link>
              </li> -->
              <li class="nav-li">
                <router-link :to="`${$t('baseurl')}/eventlive/livelist`" :class="{'active': isActive == 3}">Live Streaming</router-link>
              </li>
              <li class="nav-li">
                <router-link :to="`${$t('baseurl')}/eventlive/master`" :class="{'active': isActive == 4}">Highlight Speech</router-link>
              </li>
              <li class="nav-li">
                  <router-link :to="`${$t('baseurl')}/profile`" target="_blank">Personal Center</router-link>
              </li>
          </ul>
        </nav>

        
        <div class="sysdembtn">
          <el-space :size="8">
            <el-button v-if="!$store.getters.isLogin" type="primary" @click="toLogin"
            ><i class="iconfont icon-user-fill"></i> Log in/ Registration</el-button>

            <template v-else>
                <span class="mb-hide">
                <strong class="fcbase">{{$store.getters.truename}}</strong>, welcome to Live Streaming
                </span>
                <el-button type="primary" :icon="Search" circle @click="logout" title="Logout"><i class="iconfont icon-logout"></i></el-button>
            </template>

            <a href="https://www.sialchina.com" target="_blank" title="Back to "><el-button type="warning" circle><i class="iconfont icon-home-fill"></i></el-button></a>

            <div class="lang-box">
                <a :href="url" @click="toggleLang">中</a>
                <span>/</span>
                <a class="curr">EN</a>
            </div>
          </el-space>
      </div>
      </div>
        

    </div>
  </div>
</template>

<script>
import showMsg from "@/utils/message";
import { reactive, toRefs, ref, unref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  computed: {
    activeMenu() {
      const route = useRoute();
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      console.log("activeMenu",path);
      return path;
    },
  },
  setup() {
    const state = reactive({
      isActive: 1,
      is_nav: false,
      show_nav: true,
      url: ""
    });

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    state.isActive = route.meta.index;
    state.show_nav = route.meta.show_nav == 0 ? false : true;


    // 切换语言
    function toggleLang() {
      state.url = `${store.state.domain}${window.location.pathname}`;
    }

    const logout = async () => {
      await store.dispatch("user/logout");
      window.location.reload();
    }

    watch(() => router.currentRoute.value,(newVal)=>{
      const {meta} = newVal;
      state.isActive = meta.index;
      state.is_nav = false;
      state.show_nav = meta.show_nav == 0 ? false : true;
    })

    const toLogin = ()=>{
      store.commit("eventlive/SET_EVENTLIVE_SHOW_LOGIN",true);
    }

    const showMenu = ()=>{
      state.is_nav = !state.is_nav;
    }


    return {
      ...toRefs(state),
      logout,
      toLogin,
      showMenu,
      toggleLang
    }
  },
};
</script>

<style lang="less" scoped></style>
