<template>

  <Dialog
    ref="dialogRegRef"
    :dialogObject="dialogRegOpt"
    @dialogClose="closeDialogReg"
    class="dialog-auth"
  >
    <div class="memberauth-con login-con">
      <div class="toptxt">已有账号，<span @click="toLogin">返回登录</span></div>

      <h2 class="title2">SIAL西雅展会员注册</h2>

      <!-- 注册进度条 -->
      <regProcess :process="reg_step" :title="['创建账号','填写个人信息','完成注册']"></regProcess>

      
      <el-form
        ref="formRef"
        :model="formModel"
        :rules="formRules"
        require-asterisk-position="right"
        class="login-formbox"
        :label-width="reg_step == 2 ? 100 : 0"
      >
        <template v-if="reg_step == 1">
          <el-form-item prop="reg_type">
            <el-radio-group
              v-model="formModel.reg_type"
              @change="changeRegType"
            >
              <el-radio :label="1">中国大陆</el-radio>
              <el-radio :label="2" v-if="currentEnv != 'wx'">非中国大陆</el-radio>
            </el-radio-group>
          </el-form-item>

          <templage v-if="formModel.reg_type == 1">
            <el-form-item prop="mobile">
              <el-input
                type="text"
                v-model="formModel.mobile"
                placeholder="请输入手机号"
                class="form-input"
              />
            </el-form-item>

            <el-form-item prop="code" class="code-box">
              <el-input
                type="text"
                v-model="formModel.code"
                placeholder="手机验证码"
                class="form-input"
              >
              <template #append>
                <el-button @click="getSmsCode" class="codebtn" type="primary" :disabled="smscodeLoading">{{smscodeTxt}}</el-button>
              </template>
              </el-input>
            </el-form-item>
          </templage>

          <templage v-else>
            <el-form-item prop="email">
              <el-input
                type="text"
                v-model="formModel.email"
                placeholder="请输入邮箱"
                class="form-input"
              />
            </el-form-item>
          </templage>

          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="formModel.password"
              show-password
              placeholder="请输入密码（8-16位，字母、数字）"
              class="form-input"
            />
          </el-form-item>

          <el-form-item prop="password2">
            <el-input
              type="password"
              v-model="formModel.password2"
              show-password
              placeholder="再次输入密码"
              class="form-input"
            />
          </el-form-item>
        </template>

        <template v-else-if="reg_step == 2">

          <el-form-item prop="truename" label="姓名" required>
            <el-input
              type="text"
              v-model="formModel.truename"
              placeholder="请输入姓名"
              class="form-input"
            />
          </el-form-item>

          <el-form-item prop="gender" label="称谓" required>
            <el-radio-group
              v-model="formModel.gender"
              prop="称谓"
            >
              <el-radio :label="1">先生</el-radio>
              <el-radio :label="2">女士</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item prop="company" label="公司名称" required>
            <el-input
              type="text"
              v-model="formModel.company"
              placeholder="请输入您的公司名称"
              class="form-input"
            />
          </el-form-item>

          <el-form-item prop="position_id" label="职位" required>
            <el-input
              type="text"
              v-model="formModel.position_id"
              placeholder="请输入您的职位"
              class="form-input"
            />
          </el-form-item>

          <el-form-item prop="email" label="邮箱" required>
            <el-input
              type="text"
              v-model="formModel.email"
              placeholder="请输入邮箱"
              class="form-input"
              :disabled="formModel.reg_type == 2 && has_email"
            />
          </el-form-item>

          <el-form-item prop="mobile" label="手机" required>
            <el-row :gutter="10">
              <el-col :span="6">
                <el-input
                  type="text"
                  v-model="formModel.mobile_code"
                  placeholder="国家"
                  class="form-input"
                  :readonly="true"
                />
              </el-col>
              <el-col :span="18">
                <el-input
                  type="text"
                  v-model="formModel.mobile"
                  placeholder="请输入手机号"
                  class="form-input"
                  :disabled="formModel.reg_type == 1 && has_mobile"
                />
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item prop="country_id" label="国家/地区" required>
            <el-select
              v-model="formModel.country_id"
              value-key="id"
              filterable 
              placeholder="请选择国家/地区"
              @change="changeCountry"
              class="form-input"
              :disabled="formModel.reg_type == 1 && formModel.country_id"
            >
              <el-option disabled label="请选择" />
              <template v-for="option in countryList" :key="option.id">
                <el-option :label="option.name_cn" :value="option.id" :disabled="option.disabled"/>
              </template>
              
            </el-select>
          </el-form-item>

          <el-form-item v-show="formModel.country_id == 256" label="省份/城市" required>
            <el-row :gutter="10" class="form-inputbox">
              <el-col :span="12">
                <el-form-item prop="province_id">
                  <el-select
                    v-model="formModel.province_id"
                    class="form-input"
                    placeholder="请选择"
                    @change="changeProvice"
                  >
                    <el-option disabled label="请选择" :value="0" />
                    <template v-for="option in provinceList" :key="option.id">
                      <el-option :label="option.name_cn" :value="option.id"/>
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="city_id">
                  <el-select
                    v-model="formModel.city_id"
                    class="form-input"
                    placeholder="请选择"
                  >
                    <el-option disabled label="请选择" :value="0" />
                    <template v-for="option in cityList" :key="option.id">
                      <el-option :label="option.name_cn" :value="option.id"/>
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item prop="address" label="详细地址">
            <el-input
              type="text"
              v-model="formModel.address"
              placeholder="请输入详细地址"
              class="form-input"
            />
          </el-form-item>
        </template>
      </el-form>

      <div class="submit-box">
        <el-button type="primary" @click="doRegister" :loading="loading"
          >下一步</el-button
        >
      </div>
      

    </div>
  </Dialog>
</template>

<script>
import { reactive, toRefs, ref, unref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { isMobile, isEmail } from "@/utils/common";
import { getCountryList, getRegionList, checkVisitorExist, sendSmsCode } from "@/api/common";
import showMsg from "@/utils/message";

import { doReg } from "@/api/base";
import Dialog from "@/components/Dialog/Dialog.vue";
import regProcess from "@/views/visitor/cn/Auth/components/regProcess.vue";
import Cookies from "js-cookie";
import { getUserAgent } from "@/utils/common";

export default {
  components: {
    Dialog,
    regProcess
  },

  setup(props, ctx) {

    const store = useStore();

    const chkMobile = async(rule, val, callback) => {
      if (!val) {
        callback(new Error("请输入手机号"));
      } else if(state.formModel.country_id == 256 && !isMobile(val)) {
        callback(new Error("请输入正确的手机号"));
      } else if(!state.has_mobile && state.formModel.country_id == 256 && isMobile(val) ){
        const msg = await checkVisitorExist({ mobile: val, is_token: 1 })
          .then((res) => {
            const { data } = res;
            state.enableSmscode = true;
            state.has_mobile = true;
            state.formModel.token = data.token;
            return;
          })
          .catch((err) => {
            showMsg.error(err.msg);
            return new Error(err.msg)
          })
        callback(msg);
      }
      callback();
    };
    const chkEmail = async(rule, val, callback) => {
      if (!val) {
        callback(new Error("请输入邮箱"));
      } else if(!isEmail(val)) {
        callback(new Error("请输入正确的邮箱"));
      } else  if(!state.has_email && isEmail(val) ){
        const msg = await checkVisitorExist({ email: val, is_token: 1 })
          .then((res) => {
            const { data } = res;
            state.has_email = true;
            state.formModel.token = data.token;
            return;
          })
          .catch((err) => {
            showMsg.error(err.msg);
            return new Error(err.msg)
          })
        callback(msg);
      }
      callback();
    };

    const chkSmscode = (rule, val, callback) => {
      if (state.formModel.reg_type == 1 && !val) {
        callback(new Error("请输入手机验证码"));
      } 
      callback();
    };

    const chkPassword2 = (rule, val, callback) => {
      const password = state.formModel.password;
      if (!val) {
        callback(new Error("请再次输入密码"));
      } else if (password != val) {
        callback(new Error("两次密码不一致，请重新输入"));
      }
      callback();
    };

    const chkProvince = (rule, val, callback) => {
      if(state.formModel.country_id == 256 && !val || val == 0) {
        callback(new Error("请选择省份"));
      }
      callback();
    }

    const chkCity = (rule, val, callback) => {
      if(state.formModel.country_id == 256 && !val || val == 0) {
        callback(new Error("请选择城市"));
      }
      callback();
    }

    const state = reactive({
      loading: false,
      formModel: {
        is_current: 0,// 是否注册当届 1是 0否
        reg_type: 1,// 1:中国大陆 2：非中国大陆
        mobile: "",
        email: "",
        code: "",
        password: "",
        password2: "",
      },
      formRules: {
        mobile: [
          { required:true, validator: chkMobile, trigger: "blur" }
        ],
        email: [
          { required:true,trigger:"blur",message:"请输入邮箱" },
          { validator: chkEmail, trigger: "blur" }
        ],
        code: [{ validator: chkSmscode, trigger: "blur" }],
        password: [
          { required:true,trigger:"blur",message:"密码不能为空" },
          { pattern:/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/,message:"密码必须是包含字母、数字的8-16位组合",trigger:"blur" }
        ],
        password2: [{ validator: chkPassword2, trigger: "blur" }],
        truename: [{ required:true,trigger:"blur",message:"请输入姓名" }],
        gender: [{ required:true,trigger:"change",message:"请选择称谓" }],
        company: [{ required:true,trigger:"blur",message:"请输入您的公司名称" }],
        position_id: [{ required:true,trigger:"blur",message:"请输入您的职位" }],
        country_id: [{ required:true,trigger:"change",message:"请选择国家/地区" }],
        province_id: [{ validator: chkProvince, trigger:"change", }],
        city_id: [{ validator: chkCity, trigger:"change",}],
        // address: [{ required:true,trigger:"blur",message:"请输入详细地址" }],
      },
      smscodeTxt: "获取手机验证码",
      smscodeLoading: false,
      enableSmscode: false,
      has_mobile: false,
      has_email: false,

      countryList: [],
      chk_country: null,
      provinceList:[],
      cityList:[],

      // 注册
      reg_step: 1,
      dialogRegOpt: {
        dialogVisible: false,
        width: "600px",
        isFooter: false,
        clickModal: false
      },
      currentEnv: getUserAgent(),
    });

    // 大陆
    const changeRegType = (val) => {
      if (val == 1) {
        state.formModel.country_id = 256;
        changeCountry(256);
      }else {
        state.formModel.country_id = undefined;
        state.formModel.mobile_code = undefined;
        state.countryList.map((o,i)=>{
          if(o.id == 256) {
            state.countryList[i].disabled = true;
          }
        });
      }
    };

    // 获取国家列表
    getCountryList().then((res)=>{
      const {data} = res;
      state.countryList = data;
      
      changeRegType(state.formModel.reg_type);
    })
    
    // 获取省份列表
    getRegionList(0).then((res)=>{
      const {data} = res;
      state.provinceList = data;
      state.cityList = [];
    })

    // 国家
    const changeCountry = (id)=>{
      const option = state.countryList.filter(o=>o.id == id);
      state.formModel.mobile_code = option[0].code;
    }
    
    function changeProvice(pid) {
      state.cityList = [];
      state.formModel.city_id = 0;
      getRegionList({pid}).then((res)=>{
        const {data} = res;
        state.cityList = data;
      })
    }
    
    // 获取手机验证码
    function getSmsCode() {
      const mobile = state.formModel.mobile;

      if (!isMobile(mobile)) {
        showMsg.error("请输入有效的手机号");
        return;
      }
      console.log("enableSmscode",state.enableSmscode);
      if (state.enableSmscode) {
        sendSmsCode({ mobile, send_type: 1 })
          .then((res) => {
            var t = 60;
            var int = setInterval(function () {
              if (t == 0) {
                clearInterval(int);
                state.smscodeTxt = "获取手机验证码";
                state.smscodeLoading = false;
              } else {
                t--;
                state.smscodeTxt = `${t} s 后重新获取`;
                state.smscodeLoading = true;
              }
            }, 1000);
          })
          .catch((err) => {
            showMsg.error(err.msg);
          });
      }
    }

    const formRef = ref(null);
    watch(()=>store.getters.eventlive_show_reg,(newVal)=>{
      state.dialogRegOpt.dialogVisible = newVal;
    })

    const closeDialogReg = ()=>{
      store.commit("eventlive/SET_EVENTLIVE_SHOW_REG",false);
    }
    const toLogin = ()=>{
      // 重置初始值
      unref(formRef).resetFields();
      resetFormModel();

      store.commit("eventlive/SET_EVENTLIVE_SHOW_REG",false);
      store.commit("eventlive/SET_EVENTLIVE_SHOW_LOGIN",true);
    }

    // 注册
    const doRegister = () => {
      if(state.reg_step == 1) {
        // 第一步
        unref(formRef).validateField(["mobile","code","email","password","password2"],(valid) =>{
          if(valid) {
            // 校验通过
            state.reg_step = 2;
          }
        })
      }else {
        // 个人信息
        unref(formRef).validateField(["truename","gender","country_id","province_id","city_id","mobile","email","position_id","company"],(valid) =>{
          if(valid) {
            // 校验通过
            state.loading = true;
            doReg(state.formModel)
            .then((res) => {

              Cookies.set("isLogin",true,  { expires: 1 });
              Cookies.set("truename",state.formModel.truename, { expires: 1 });
              store.commit("user/SET_STATUS", true);
              store.commit("user/SET_TRUENAME", state.formModel.truename);

              store.commit("eventlive/SET_EVENTLIVE_SHOW_REG",false);
              store.commit("eventlive/SET_EVENTLIVE_SHOW_FINISH",true);
              unref(formRef).resetFields();
              resetFormModel();
              // 更新角色
              store.dispatch("user/changeRoles");
              state.loading = false;
            })
            .catch((err) => {
              state.loading = false;
              showMsg.error(err.msg);
              state.reg_step = 1;
              state.has_mobile = false;
              state.has_email = false;
            });
          }
        })
      }
      
    }

    // 重置初始值
    const resetFormModel = ()=>{
      state.reg_step = 1;
      state.formModel.reg_type = 1;
      state.formModel.mobile = "";
      state.formModel.email = "";
      state.formModel.code = "";
      state.formModel.password = "";
      state.formModel.password2 = "";
    }

    return {
      ...toRefs(state),
      closeDialogReg,
      changeRegType,
      changeCountry,
      changeProvice,
      formRef,
      getSmsCode,
      doRegister,
      toLogin
    };
  },
};
</script>

<style lang="less" scoped>
</style>
