<template>
  <div class="elive-header">
    <div class="elive-wrap">
      <div class="con">
        
        <div class="logo">
          <router-link to="/eventlive"><img src="@/assets/images/logo.png?v=2023" alt="西雅展LOGO"
          /></router-link>
        </div>

        <div class="menubtn mb-show" :class="{'active' : is_nav}" @click="showMenu">
          <span></span>
          <span></span>
          <span></span>
        </div>

        <nav class="navbox" :class="{'active' : is_nav}" v-show="show_nav">
          <ul>
              <li class="nav-li">
                <router-link to="/eventlive" :class="{'active': isActive == 1}">首页</router-link>
              </li>
              <!-- <li class="nav-li">
                <router-link to="/forumticket" :class="{'active': isActive == 2}">线下峰会</router-link>
              </li> -->
              <li class="nav-li">
                <router-link to="/eventlive/livelist" :class="{'active': isActive == 3}">峰会直播</router-link>
              </li>
              <li class="nav-li">
                <router-link to="/eventlive/master" :class="{'active': isActive == 4}">峰会大咖说</router-link>
              </li>
              <li class="nav-li">
                  <router-link to="/invoice" target="_blank">申请发票</router-link>
              </li>
              <li class="nav-li">
                  <router-link to="/profile" target="_blank">个人中心</router-link>
              </li>
          </ul>
        </nav>

        
        <div class="sysdembtn">
          <el-space :size="8">
            <el-button v-if="!$store.getters.isLogin" type="primary" @click="toLogin"
            ><i class="iconfont icon-user-fill"></i> 登录/注册</el-button>

            <template v-else>
                <strong class="fcbase">{{$store.getters.truename}}</strong><span class="mb-hide">，欢迎登录峰会直播</span>

                <el-button type="primary" :icon="Search" circle @click="logout" title="退出"><i class="iconfont icon-logout"></i></el-button>
            </template>

            <a href="https://www.sialchina.cn" target="_blank" title="返回官网"><el-button type="warning" circle><i class="iconfont icon-home-fill"></i></el-button></a>

            <div class="lang-box">
                <a class="curr">中</a>
                <span>/</span>
                <a :href="url" @click="toggleLang">EN</a>
            </div>
          </el-space>
      </div>
      </div>
        

    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { getUserAgent } from "@/utils/common";


export default {
  computed: {
    activeMenu() {
      const route = useRoute();
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
  setup() {
    const state = reactive({
      isActive: 1,
      is_nav: false,
      show_nav: true,
      url: "",
      currentEnv: getUserAgent(),
    });

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    state.isActive = route.meta.index;
    state.show_nav = route.meta.show_nav == 0 ? false : true;

    
    // 切换语言
    function toggleLang() {
      state.url = `${store.state.domain_en}${window.location.pathname}`;
    }

    const logout = async () => {
      await store.dispatch("user/logout");
      window.location.reload();
    }

    watch(() => router.currentRoute.value,(newVal)=>{
      const {meta} = newVal;
      state.isActive = meta.index;
      state.is_nav = false;
      state.show_nav = meta.show_nav == 0 ? false : true;
    })

    const toLogin = ()=>{
      store.commit("eventlive/SET_EVENTLIVE_SHOW_LOGIN",true);
    }

    const showMenu = ()=>{
      state.is_nav = !state.is_nav;
    }

    onMounted(()=>{
      // TDK
      document.title = "2024食品展SIAL世界食品产业峰会官方购票入口：线下峰会|峰会直播|峰会大咖说";

      // 设置页面描述
      document.querySelector('meta[name="description"]').setAttribute('content', "SIAL世界食品产业峰会旨在传递食饮行业前沿资讯，剖析行业发展新趋势。峰会围绕新零售、供应链、乳业、肉类、饮品、食品科技、进口食品、食品投融资、中餐等食饮行业热门话题及品类，邀请全球顶尖专家学者参与并发表主题演讲，参会人士也有机会就行业热门议题进行深入探讨，助力食饮企业获得更多高价值合作商机和行业信息。购票咨询电话：010 - 8572 8347，邮箱：ina.zhao@sialchina.cn");
    
      // 设置页面关键词
      document.querySelector('meta[name="keywords"]').setAttribute('content', "2024食品展，SIAL世界食品产业峰会，峰会直播，峰会大咖说，线下峰会");
      
    })


    return {
      ...toRefs(state),
      logout,
      toLogin,
      showMenu,
      toggleLang
    }
  },
};
</script>

<style lang="less" scoped></style>
