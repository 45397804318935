<template>
  <div class="pageHead">
    <div class="logobox">
          <img src="@/assets/images/logo3.png">
      </div>
    <div class="navtop">
      <div class="navbox">
        <router-link :to="`${$t('baseurl')}/live`" class="linka"><i class="iconfont icon-icon_zhibo-xian"></i> Live Streaming</router-link>
        <!-- <router-link :to="`${$t('baseurl')}/onsite/forums`" class="linka"><i class="iconfont icon-outline-message-question"></i>  Summit Tickets</router-link> -->
        <router-link :to="`${$t('baseurl')}/download`" class="linka"><i class="iconfont icon-shu2"></i>  White Paper Download</router-link>
        <a href="https://catalogue.sialchina.cn/en" class="linka" target="_blank"><i class="iconfont icon-dianzihuikan"></i>  Online Catalogue</a>
      </div>
      <div class="system-btn">
        <a href="https://www.sialchina.com/" target="_blank" class="btn gohome">Website</a>
        <a class="linka link-home" :href="`${$t('baseurl')}`" title="Home"><i class="iconfont icon-home-fill"></i></a>
        <a class="linka link-account" :href="`${$t('baseurl')}/profile`" title="Personal Information"><i class="iconfont icon-user-fill"></i></a>
        <a class="linka link-outlog mb-hide" @click="logout" title="Exit"><i class="iconfont icon-logout"></i></a>

        <div class="menubtn mb-show" :class="{'active' : is_nav}" @click="showMenu">
          <span></span>
          <span></span>
          <span></span>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
// export default {
//   methods: {
//     async logout() {
//       await this.$store.dispatch("user/logout");
//       this.$router.push(`/login?redirect=${this.$route.fullPath}`);
//     },
//   }
// };

import { reactive, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup(props, ctx){
    const { t } = useI18n();

    const state = reactive({
      is_nav: false,
      url: ""
    })

    const router = useRouter();
    const store = useStore();


    const logout = ()=>{
      store.dispatch("user/logout").then(()=>{
        router.push(`${t('baseurl')}/login`);
      });
      
    }

    const showMenu = ()=>{
      state.is_nav = !state.is_nav;
      ctx.emit("showMenu",state.is_nav);
    }

    watch(() => router.currentRoute.value,(newVal)=>{
      state.is_nav = false,
      ctx.emit("showMenu",state.is_nav);
    })


    return {
      ...toRefs(state),
      logout,
      showMenu,
    }
  }
};
</script>
