

export function tongjiHeadEn() {
    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://www.googletagmanager.com/gtag/js?id=G-6FNB67RTRN';
    scriptElement.setAttribute('async', 'true');
    document.head.appendChild(scriptElement);

    const initScriptElement = document.createElement('script');
    initScriptElement.textContent = `
    window.dataLayer = window.dataLayer || []; 
    function gtag(){dataLayer.push(arguments);} 
    gtag('js', new Date()); 

    gtag('config', 'G-6FNB67RTRN');
    `;
    document.head.appendChild(initScriptElement);

    // const baiduScriptElement = document.createElement('script');
    // baiduScriptElement.textContent = `
    //     var _hmt = _hmt || [];
    //     window._hmt = _hmt;
    //     (function() {
    //     //每次执行前，先移除上次插入的代码
    //     document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
    //     var hm = document.createElement("script");
    //     hm.src = "https://hm.baidu.com/hm.js?7d39bada206a30a7d5d277b59519b25f";
    //     hm.id = "baidu_tj"
    //     var s = document.getElementsByTagName("script")[0];
    //     s.parentNode.insertBefore(hm, s);
    //     })();
    // `;
    // document.head.appendChild(baiduScriptElement);
}

export function baiduTongjiEn(to) {
    var _hmt = window._hmt || [];
    (function() {
        document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?7d39bada206a30a7d5d277b59519b25f";
        hm.id = "baidu_tj"
        var s = document.getElementsByTagName("script")[0]; 
        s.parentNode.insertBefore(hm, s);
        hm.onload = function() {
            if (to.path) {
                _hmt.push(['_trackPageview', to.fullPath])
            }
        };
    })();
    
}

export function baiduTongjiZhcn(to) {
    var _hmt = window._hmt || [];
    (function() {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?9e5f9378b90fb38a902e254ac170f88e";
        var s = document.getElementsByTagName("script")[0]; 
        s.parentNode.insertBefore(hm, s);
        hm.onload = function() {
            if (to.path) {
                _hmt.push(['_trackPageview', to.fullPath])
            }
        };
    })();
    
}