import { createI18n } from "vue-i18n";
import en from "./lang/en";
import cn from "./lang/cn";

function getLanguage() {
  // 检查域名以确定语言
  if (window.location.hostname.endsWith('.com') || window.location.pathname.indexOf("/en") == 0) {
    return 'en';
  } else {
    return 'cn'; 
  }
}

const i18n = createI18n({
  legacy: false,
  locale: getLanguage(), // 语言标识（缓存里面没有就用中文）
  fallbackLocale: "en", //没有英文的时候默认语言
  globalInjection: true, // 全局注册$t方法
  messages: {
    cn,
    en,
  },
});

// i18n.watch('locale', (newLocale) => {
//   // 更新当前语言状态
//   pinia.dispatch('yourStore/setCurrentLanguage', newLocale);
//   // 重新加载百度统计脚本，例如移除旧的脚本并添加新的
//   const oldHmScript = document.getElementById('hm-script');
//   if (oldHmScript) {
//     oldHmScript.remove();
//   }
//   // 添加新脚本，这里假设新的ID是基于新的语言
//   const newHmScript = document.createElement('script');
//   newHmScript.src = `https://hm.baidu.com/hm.js?${newLocale === 'zh-CN' ? 'cn-id' : 'en-id'}`;
//   document.head.appendChild(newHmScript);
// });

export default i18n;
