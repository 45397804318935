import request from "@/utils/request";
import request_journal from "@/utils/request_journal";

/**
 *  获取展会列表
 * @param {*}
 * @returns {}
 */
export function getExpoAreaList(params) {
  return request({
    url: "/getExpoAreaList",
    method: "GET",
    params,
  });
}

/**
 *  获取国家
 * @param {*}
 * @returns {}
 */
export function getCountryList(params) {
  return request({
    url: "/getCountryList",
    method: "GET",
    params,
  });
}

/**
 *  获取省市区
 * @param {*}  pid
 * @returns {}
 */
export function getRegionList(params) {
  return request({
    url: "/getRegionList",
    method: "GET",
    params,
  });
}

/**
 * 验证手机或邮箱是否存在
 * @data {*} mobile
 * @data {*} email
 * @returns
 */
export function checkVisitorExist(data) {
  return request({
    url: "/checkVisitorExist",
    method: "POST",
    data,
  });
}



/**
 * 发送邮箱
 * @data {*} mobile 手机
 * @data {*} send_type 发送类型 1观众注册 2观众登陆
 * @returns
 */
 export function sendSmsCode(data) {
    return request({
      url: "/sendSmsCode",
      method: "POST",
      data,
    });
}


/**
 * 获取展会状态
 * @returns
 */
 export function getExpoAreaStatus(params) {
  return request({
    url: "/getExpoAreaStatus",
    method: "GET",
    params,
  });
}


/**
 * 获取产品所属分类树
 * @returns
 */
 export function getIndustryTree(params) {
  return request({
    url: "/getIndustryTree",
    method: "GET",
    params,
  });
}


/**
 * 更新业务员类型树
 * @returns
 */
 export function getBusinessTree(params) {
  return request({
    url: "/getBusinessTree",
    method: "GET",
    params,
  });
}


/**
 * 获取兴趣主题
 * @returns
 */
export function getInterestThemeList(params) {
  return request({
    url: "/getInterestThemeList",
    method: "GET",
    params,
  });
}

/**
 * 获取微信config
 * @returns
 */
export function getWxConfig(params) {
  return request({
    url: "/getWxConfig",
    method: "GET",
    params,
  });
}
/**
 * 获取微信open_id
 * @returns
 */
export function getWxOpenId(params) {
  return request({
    url: "/getWxOpenId",
    method: "GET",
    params,
  });
}

/**
 * 电子会刊微信支付
 * @returns
 */
export function getJournalWxJsApiPay(params) {
  return request_journal({
    url: "/getJournalWxJsApiPay",
    method: "GET",
    params,
  });
}

/**
 * 微信绑定
 * @data {*} code 
 * @returns
 */
export function wxBind(data) {
  return request({
    url: "/wxBind",
    method: "POST",
    data,
  });
}


/**
 * 微信登录
 * @returns
 */
export function wxLogin(data) {
  return request({
    url: "/wxLogin",
    method: "POST",
    data,
  });
}


/**
 * 观众线下峰会微信支付
 * @returns
 */
export function getTicketWxJsApiPay(params) {
  return request({
    url: "/getTicketWxJsApiPay",
    method: "GET",
    params,
  });
}


/**
 * 观众直播微信支付
 * @returns
 */
export function getLiveWxJsApiPay(params) {
  return request({
    url: "/getLiveWxJsApiPay",
    method: "GET",
    params,
  });
}
