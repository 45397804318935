<template>
  <div class="pageHead">
    <div class="logobox">
          <img src="@/assets/images/logo3.png">
      </div>
    <div class="navtop">
      <div class="navbox">
        <router-link to="/live" class="linka"><i class="iconfont icon-icon_zhibo-xian"></i> 峰会直播</router-link>
        <!-- <router-link to="/onsite/forums" class="linka"><i class="iconfont icon-outline-message-question"></i>  线下峰会</router-link> -->
        <router-link to="/download" class="linka"><i class="iconfont icon-shu2"></i>  白皮书下载</router-link>
        <router-link to="/salon" class="linka"><i class="iconfont icon-lianxiwomen"></i>  沙龙报名</router-link>
        <router-link to="/invoice" class="linka"><i class="iconfont icon-fapiaoguanli"></i>  发票申请</router-link>
        <a href="https://catalogue.sialchina.cn/" class="linka" target="_blank"><i class="iconfont icon-dianzihuikan"></i>  电子会刊</a>
      </div>
      <div class="system-btn">
        <a href="https://sialchina.cn/" target="_blank" class="btn gohome">返回官网</a>
        <a class="linka link-home" href="/" title="首页"><i class="iconfont icon-home-fill"></i></a>
        <a class="linka link-account" href="/profile" title="个人信息"><i class="iconfont icon-user-fill"></i></a>
        <a class="linka link-outlog mb-hide" @click="logout" title="退出"><i class="iconfont icon-logout"></i></a>

        <div class="menubtn mb-show" :class="{'active' : is_nav}" @click="showMenu">
          <span></span>
          <span></span>
          <span></span>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  setup(props, ctx){
    const router = useRouter();
    const store = useStore();

    const state = reactive({
      is_nav: false,
      url: ""
    })

    const logout = ()=>{
      store.dispatch("user/logout").then(()=>{
        router.push("/login");
      });
      
    }

    const showMenu = ()=>{
      state.is_nav = !state.is_nav;
      ctx.emit("showMenu",state.is_nav);
    }

    watch(() => router.currentRoute.value,()=>{
      state.is_nav = false,
      ctx.emit("showMenu",state.is_nav);
    })


    return {
      ...toRefs(state),
      logout,
      showMenu,
    }
  }
};
</script>
