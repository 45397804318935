<template>
  <el-config-provider :locale="configLocale">
    <div>
      <router-view ></router-view>
    </div>
  </el-config-provider>
</template>

<script>
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import en from "element-plus/dist/locale/en.mjs";
import { useI18n } from "vue-i18n";
import { reactive, ref, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { tongjiHeadEn } from "@/utils/tongji";

export default {
  name: "App",
  components: {},
  setup() {
    const { t, locale } = useI18n();
    const router = useRouter();
    const state = reactive({
    })
    const debounce = (fn, delay) => {
      let timer = null;
      return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          fn.apply(context, args);
        }, delay);
      };
    };
    const _ResizeObserver = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
      constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
      }
    };

    console.log("locale.value",locale.value);

    const configLocale = ref(null);
    if (locale.value == "en") {
      configLocale.value = en;
    } else {
      configLocale.value = zhCn;
    }
    
    onMounted(()=>{

      // TDK
      if(locale.value == "en") {
        // 有“|” 使用t("head_title") |后面的文本不显示
        document.title = "sial shanghai|sial shenzhen-Visitor Member Center-SIAL China";
      }else {
        document.title = t("head_title");
      }
      

      // 设置页面描述
      document.querySelector('meta[name="description"]').setAttribute('content', t("head_description"));
    
      // 设置页面关键词
      document.querySelector('meta[name="keywords"]').setAttribute('content', t("head_keywords"));
      
    })

    
    // 添加统计代码
    router.afterEach( ( to, from, next ) => {
      if(process.env.NODE_ENV == "production") {
        if (window._hmt && to.path) {
          window._hmt.push(['_trackPageview', to.fullPath])
        }
        if(locale.value == "en") {
          tongjiHeadEn();
        }
      }
    } );

    return {
      ...toRefs(state),
      configLocale,
    };
  },
};
</script>
