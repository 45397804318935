<template>
  <el-dialog
    v-model="dialogShow"
    close-icon="CircleCloseFilled" 
    :align-center="true"
    :width="obj.dialogWidth"
    :draggable="obj.draggable"
    :before-close="close"
    :destroy-on-close="obj.destroyOnClose"
    :close-on-click-modal="obj.clickModal"
    :class="{'dialog-hastit': dialogTitle}"
  >

  <template #header>
    <slot name="title">
      <div class="dialog-title" v-if="dialogTitle">
        <h4 class="title">{{ dialogTitle }}</h4>
      </div>
    </slot>
  </template>
  
  <div class="dialog-body">
    <slot> </slot>
  </div>

    <template #footer v-if="dialogIsFooter">
      <slot name="footcon">
        <el-button type="info" plain size="large" @click="close">{{
          obj.dialogCloseBtnText
        }}</el-button>
        <el-button
          type="primary"
          @click="success"
          size="large"
          :loading="obj.loading"
          >{{ obj.dialogSuccessBtnText }}</el-button
        >
      </slot>
    </template>
  </el-dialog>
</template>

<script>
import { computed } from "vue";
import { useI18n } from 'vue-i18n';

export default {
  props: {
    dialogObject: {
      default() {
        return {};
      },
      type: Object,
    },
  },
  emits: ["dialogClose", "dialogSuccess"],
  setup(props, ctx) {
    const { t } = useI18n() 

    // 处理props的数据 重新定义 dialogVisible
    const dialogShow = computed({
      get() {
        return props.dialogObject.dialogVisible;
      },
      set(val) {
        return val;
      },
    });

    const dialogIsFooter = computed({
      get(){
        return props.dialogObject.isFooter == undefined ? true : props.dialogObject.isFooter
      }
    })

    const dialogTitle = computed({
      get(){
        return props.dialogObject.title  || ""
      }
    })
    const obj = {
      dialogWidth: props.dialogObject.width || "90%",
      dialogCloseBtnText: props.dialogObject.closeBtnText || t("cancel"),
      dialogSuccessBtnText: props.dialogObject.successBtnText || t("confirm"),
      // dialogIsFooter: props.dialogObject.isFooter == undefined ? true : props.dialogObject.isFooter,
      loading: props.dialogObject.loading || false,
      draggable: props.dialogObject.loading || false,
      clickModal: props.dialogObject.clickModal == undefined ? true : false,
      destroyOnClose: props.dialogObject.destroyOnClose == undefined ? true : false,
    };
    
    const close = () => {
      ctx.emit("dialogClose", false, "close");
    };
    const success = () => {
      ctx.emit("dialogSuccess", false, "success");
    };
    return { obj, dialogTitle, dialogShow, dialogIsFooter, close, success };
  },
};
</script>
<style lang="less">

// 弹窗
.el-dialog {
  max-width: 90%;
}
.el-dialog__body {
  color: #333;
  max-height: 88vh;
  overflow-y: auto;
}
.el-dialog__headerbtn {
  font-size: 28px !important;
  z-index: 2;
}
.el-dialog__headerbtn:focus .el-dialog__close, 
.el-dialog__headerbtn:hover .el-dialog__close {
  color: @color_deep !important;
}
.el-overlay-dialog {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.el-dialog__footer {
  text-align: center !important;
}
.dialog-title {
  font-weight: bold;
  font-size: 16px;
}
.dialog-hastit {
  .el-dialog__header {
    border-bottom: 1px solid @linebg;
    margin-left: 16px;
    padding-left: 0;
  }
}
// 弹窗end
</style>
